.LoginScreen {
  margin: 20vh auto;
  max-width: 640px;

  h1 {
    text-align: center;
  }

  .PulseLoader {
    text-align: center;
  }
}