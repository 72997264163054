@import "../../styles/colors.scss";
@import "../../styles/responsive.scss";

.nav-item {
  margin-left: 1rem;
}

.DashboardNavigation .navbar {
  background-color: $white !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;

  .navbar-brand {
    max-width: 100px;

    img {
      width: 100%;
    }
  }

  .Sentry {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-left: 1rem;
    text-transform: initial;
    color: $darkGrey;
  
    &:hover {
      background-color: transparent;
      text-decoration: underline;
      border: 0;
      color: $green;
    }
  
    &:focus {
      outline: 0;
      background-color: transparent !important; 
      color: $green;
    }
  
    &:active {
      background-color: transparent !important; 
      text-decoration: underline;
      color: $green;
      border: 0;
    }
  }
}

.MapNavigation {
  background: black url('/assets/images/tires-tall.jpg') no-repeat center center;
  background-size: cover;

  .navbar {
    background-color: transparent !important;
  }

  .navbar-brand {
    max-width: 150px;
    width: 100%;

    img {
      width: 100%;
    }
  }

  color: $white !important;

  a {
    color: $white !important;
  }

  .Sentry {
    color: $white !important;
    background-color: transparent !important;
    text-transform: initial;

    &:hover {
      text-decoration: underline;
    }
  }

  .Mui-focused {
    color: $white !important;

    .MuiOutlinedInput-notchedOutline {
      border-color: $white !important;
    }
  }

  .MuiInputBase-root {
    background-color: rgba(0,0,0,0.3);
    border-radius: 0;
  }

  .MuiInputBase-root,
  .MuiFormLabel-root {
    color: $white;

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: $white;
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $white;
  }

  .MuiSvgIcon-root {
    fill: $white;
  }

  #submitSearch {
    padding: 15px 4.5px;
    height: auto;
    border-bottom: 1px solid $green;
  }

  input {
    margin-left: 0.5rem;

    &[type=submit] {
      max-width: 100px;
    }
  }

  .Sentry {
    margin-right: 0.5rem;
  }

  .MuiAutocomplete-root {
    margin-left: 1rem;
    width: 35%;
    max-width: 240px;
  }

  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .OpenMobileSearch {
    display: none;
  }

  .SearchBoxes {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

  .NavigationControls {
    display: flex;

    button {
      margin: 1rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.navbar-nav {
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: $break1) {
  .navbar {
    padding: 0 1rem !important;
  }
  .navbar .container {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
  }

  .MapNavigation {
    input {
      margin-left: 0.25rem;
  
      &[type=submit] {
        max-width: 80px;
      }
    }
  }
}

@media screen and (max-width: $break2) {
  .navbar {
    height: 10vh !important;

    .container {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  .Sentry {
    display: none;
  }

  .MapNavigation {
    .SearchBoxes {
      display: none;
      position: fixed;
      top: 10vh;
      width: 100%;
      background-color: $blue;
      left: 0;
      padding: 1rem;

      &.Open {
        display: block;
      }
    }

    .OpenMobileSearch {
      display: block;
      font-weight: bold;
      border: 0;
    }

    .MuiAutocomplete-root {
      width: 100%;
      max-width: 100%;
      margin-bottom: 1rem;
      margin-left: 0;
    }
  }

  #submitSearch {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }

  .navbar-nav {
    display: none;
  }

  .SearchBoxes {
    display: none;

    &.Open {
      display: block;
    }
  }

  .OpenMobileSearch {
    color: $white !important;
    background-color: transparent !important;
    padding: 0;
  }

  .navbar-brand {
    img {
      max-height: 50px;
    }
  }
}