@import "./styles/colors.scss";

html,
body {
  font-family: proxima-soft, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: $darkGrey;
}

i,
em {
  font-family: proxima-soft, sans-serif;
  font-style: italic;
}

b,
strong {
  font-family: proxima-soft,sans-serif;
  font-weight: 500;
  font-style: normal;
}

.LoadingScreen {
  margin: 45vh 0 0;
  text-align: center;
}

#root > div > .container > div:first-child {
  min-height: 70vh;
}

a {
  color: $darkGrey;

  &:hover {
    color: $lightGreen;
  }
}

.SectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 4.125rem;
  background-color: $white !important;
  z-index: 10;
}

.FormFooter {
  .PulseLoader {
    margin: 2rem 0 !important;
    text-align: center;
  }
}

.View {
  padding-top: 6rem;

  .PulseLoader {
    text-align: center;
    margin: 4rem auto;
  }
}

.CheckboxLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.75rem;

  .description {
    line-height: 1;
    span {
      line-height: 0;
    }
  }

  input {
    margin-right: 1rem;
  }

  small {
    margin-top: 0.15rem;
    display: block;
  }
}

.Scrollable {
  max-height: 300px;
  overflow: scroll;
}

.table {
  td {
    vertical-align: middle;
  }
}

.SearchHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    max-width: 320px;
  }

  .btn {
    white-space: nowrap;
    margin-left: 0.5rem;
  }
}

label {
  font-weight: 500;

  & + p {
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
  }
}

h1 {
  font-size: 1.75rem
}

.btn {
  border-color: $green;
  border-radius: 0;
  text-transform: uppercase;

  &.btn-primary {
    background-color: $green;
    text-decoration: none !important;
  
    &:hover,
    &:active,
    &:focus {
      background-color: $lightGreen !important;
      border-color: $lightGreen !important;
    }
  }

  &.btn-secondary {
    background-color: $darkGrey;
    color: $white;
    border: 0;
    
    &:hover {
      background-color: $hoverDarkGrey;
    }
  }
}

.h5 {
  font-weight: 700;
}